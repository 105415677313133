// ACHTUNG!! Muss JSON sein, keine Komentare!
export default {
  "entrypoints": {
    "mainnavigation": {"php": true},
    "contact": {"php": false},
    "swiper": {"php": false},
    "accordion": {"php": false},
    "lightgallery": {"php": false},
    "immo-expose": {"php": false},
    "immoapp": {"php": false},
    "immoapp-savedsearch": {"php": false},
    "immoapp-search": {"php": false},
    "immoapp-leadwizard": {"php": false},
    "immoapp-exposecontact": {"php": false},
    "tabs": {"php": false},
    "modal": {"php": false},
    "map": {"php": false}
  }
}
