import ImmoappLoader from "../misc/ImmoappLoader";
import SearchApp from "../components/immoapp/SearchApp";

export default function() {
	const element = document.querySelector("#vue-immoapp-search");
	if (!element) {
		return;
	}

	ImmoappLoader(element, SearchApp, {
		props: {
		categories: JSON.parse(element.dataset.categories),
		params: JSON.parse(element.dataset.params),
		action: element.dataset.action
		}
	});
}